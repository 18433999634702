<script setup>
import {ref,watch,onMounted,onUnmounted} from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { storeToRefs } from 'pinia'
import { useCounterStore } from '@/stores/counter'
import WxPopup from './WxPopup.vue'

// const i18n = useI18n()
const { locale } = useI18n()
// console.log(i18n.locale)
const route = useRoute()
console.log(route)

const isopen = ref(false);
const changeLocale = (value) => {
    locale.value = value;//赋值切换语言
    localStorage.setItem('locale', value);//修改本地存储
}

const store = useCounterStore()
const { typeid,year } = storeToRefs(store) 

const pageName = ref('')
//监控路由变化
watch(
  () => route.name,
  async newName => {
    console.log(newName)
    pageName.value = newName;
  }
)

const el = ref()
const hcon = ref()
const subnav = ref()
let toTop = document.getElementById("toTop");


const handleScroll = ()=>{
  if(!toTop){
    toTop = document.getElementById("toTop");
  }
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  let winWidth = document.body.clientWidth;
  // console.log(winWidth)
  if(scrollTop>88&&winWidth<600){
    el.value.style.position="fixed";
    el.value.style.top="0";
    hcon.value.style.height="104px";
  }
  if(scrollTop<88&&winWidth<600){
    el.value.style.position="relative";
    el.value.style.top="0px";
    hcon.value.style.height="56px";
  }
  if(winWidth>600&&scrollTop>81){
    subnav.value.style.position="fixed";
    hcon.value.style.height="92px";
  }
  if(winWidth>600&&scrollTop<81){
    subnav.value.style.position="relative";
    hcon.value.style.height="56px";
  }

  if(scrollTop>200&&!!toTop){
    toTop.style.visibility='visible'
  }else{
    toTop.style.visibility='hidden'
  }
}

onMounted(() => {
  el.value
  window.addEventListener("scroll",handleScroll);
})
onUnmounted(()=>{
  document.removeEventListener('scroll', handleScroll);
})

const changeType = (id)=>{
  //  console.log(id)
   store.setTypeId(id)
}


</script>

<template>

  <header :class="{'en':locale==='en','zh':locale==='zh'}">
    <div class="topbar">
      <div class="wrap">
            <div><a href="https://gather.fstcb.com/index.html?source=5" target="_blank">{{ locale==='zh'?'海外邮件订阅':'CTW Newsletter' }}</a></div>
            <span> | </span>
            <div class="follow-us" v-if="locale==='zh'">
              <span class="a" @click="isopen = true">{{ locale==='zh'?'关注我们':'Follow Us' }}: </span>
              <img @click="isopen = true" src="../assets/weiixn.png" />
            </div>
            <div class="follow-us" v-if="locale==='en'">
              <span class="a">{{ locale==='zh'?'关注我们':'Follow Us' }}: </span>
              <a href="https://twitter.com/CeramicTWeekly" target="_blank"><img src="../assets/icon-t.png" /></a>
              <a href="https://www.facebook.com/ceramictownweekly" target="_blank"><img src="../assets/icon-yg.png" /></a>
              <a href="https://www.youtube.com/channel/UCuRPCkjpt4trT9Fnoe0B60A" target="_blank"><img src="../assets/icon-facebook.png" /></a>
            </div>
      </div>
    </div>
    <div class="headerCon" ref="hcon">
      <div class="leftbox">
        <RouterLink to="/index" class="logo" ><img src="../assets/logo.png" height="44"/></RouterLink>
        <div class="navbox mobile-hide">
            <RouterLink to="/index">{{ locale==='zh'?'榜单':'List' }}</RouterLink>
            <RouterLink to="/news">{{ locale==='zh'?'百强会客厅':'Global 100 Reception Hall' }}</RouterLink>
            <RouterLink to="/cnews">{{ locale==='zh'?'企业新闻':'Company News' }}</RouterLink>
        </div>
      </div>
      <div class="langbox">
         <span :class="{'che':locale==='zh'}" @click="changeLocale('zh')">中文</span>
         <span :class="{'che':locale==='en'}" @click="changeLocale('en')">En</span>
      </div>
    </div>

    <div class="navbox-m pc-hide" ref="el">
         <RouterLink v-if="pageName==='index'||pageName==='news'||pageName==='cnews'" to="/index"><span>{{ locale==='zh'?'榜单':'List' }}</span></RouterLink>
         <RouterLink v-if="pageName==='index'||pageName==='news'||pageName==='cnews'" to="/news"><span>{{ locale==='zh'?'百强会客厅':'Global 100 Reception Hall' }}</span></RouterLink>
         <RouterLink v-if="pageName==='index'||pageName==='news'||pageName==='cnews'" to="/cnews"><span>{{ locale==='zh'?'企业新闻':'Company News' }}</span></RouterLink>
         <a class="back" v-if="pageName!=='index'&&pageName!=='news'&&pageName!=='cnews'" href="javascript:history.go(-1)"><span>{{ locale==='zh'?'返回':'Back' }}</span></a>
   </div>
    <div class="subnav" ref="subnav">
      <div class="wrap">
        <ul v-if="pageName==='index'">
            <li :class="{'che':typeid==1}" @click="changeType(1)">{{ locale==='zh'?'世界陶瓷卫浴100强':'Global 100' }}</li>
            <li :class="{'che':typeid==2}" @click="changeType(2)">{{ locale==='zh'?'全球陶瓷30强':'Ceramics Global 30' }}</li>
            <li :class="{'che':typeid==3}" @click="changeType(3)">{{ locale==='zh'?'全球卫浴30强':'Sanitary Ware Global 30' }}</li>
            <li :class="{'che':typeid==4}" @click="changeType(4)">{{ locale==='zh'?'陶瓷装备和材料企业15强':'Equipment and Materials Global 15' }}</li>
            <li v-if="year==='2022'" :class="{'che':typeid==5}" @click="changeType(5)">{{ locale==='zh'?'全球陶瓷产量30强':'Production Global 30' }}</li>
            <li v-if="year==='2022'" :class="{'che':typeid==6}" @click="changeType(6)">{{ locale==='zh'?'陶瓷产能30强(中国)':'Production Capacity Top 30(China)' }}</li>
            <!-- <li v-if="year==='2022'" :class="{'che':typeid==7}" @click="changeType(7)">{{ locale==='zh'?'陶瓷单一品牌30强(中国)':'Single Brand Top 30(China)' }}</li> -->
        </ul>
        <div v-if="pageName!=='index'" class="breadcrumb">
            <RouterLink to="/index">{{ locale==='zh'?'首页':'Home' }}</RouterLink>
            <span class="arrow">></span>

            <span v-if="pageName==='news'">{{ locale==='zh'?'百强会客厅':'Global 100 Reception Hall' }}</span>
            <span v-if="pageName==='cnews'">{{ locale==='zh'?'企业新闻':'Company News' }}</span>
            <span v-if="pageName==='about'">{{ locale==='zh'?'关于我们':'About us' }}</span>
            <span v-if="pageName==='about2'">{{ locale==='zh'?'关于榜单':'About listing' }}</span>
            <span v-if="pageName==='wzsm'">{{ locale==='zh'?'网站声明':'Website Statement' }}</span>
            <span v-if="pageName==='show'">{{ locale==='zh'?'企业详情':'Company Details' }}</span>

            <RouterLink to="/news" v-if="pageName==='detail'" class="span">{{ locale==='zh'?'百强会客厅':'Global 100 Reception Hall' }}</RouterLink>
            <RouterLink to="/cnews" v-if="pageName==='cdetail'" class="span">{{ locale==='zh'?'企业新闻':'Company News' }}</RouterLink>
            <span class="arrow" v-if="pageName==='detail'||pageName==='cdetail'">></span>
            <span v-if="pageName==='detail'||pageName==='cdetail'">{{ locale==='zh'?'正文':'Article' }}</span>
        </div>
      </div>
    </div>

    <WxPopup :show="isopen" @my-close="isopen = false"></WxPopup>

  </header>

</template>

<style scoped>
header{background: #042246; color:#fff; width:100vw;}
header .topbar{height:25px; line-height:25px; background: #001d35; color:#fff;}
header .topbar .wrap{width:1100px; margin:0 auto; display:flex; justify-content: flex-end; font-size:13px;}
header .topbar .wrap .follow-us{font-size:0; display: flex;}

header .topbar .wrap span{margin:0 8px; cursor: pointer; font-size: 13px;;}
header .topbar .wrap a{text-decoration: none; color:#fff;}
header.zh .topbar .wrap a:hover,header.zh .topbar .wrap .a:hover{text-decoration: underline; cursor: pointer;}
header .topbar .wrap img{width:25px; height: 25px; cursor:pointer; margin-left:2px;}
header .headerCon{width:1100px; margin:0 auto; padding:10px 0; height:56px; display:flex;  justify-content: space-between; align-items: center;}
header .headerCon .leftbox{display:flex; align-items: center;}
header .headerCon .leftbox .logo{margin-right:100px; margin-top:5px;}
header .headerCon .navbox a{display:inline-block; min-width:135px; height:56px; line-height:56px; color:#fff; text-align:center; font-size:16px; transition-duration: 300ms; padding:0 15px;}
header .headerCon .navbox a:hover{background:#003677;}
header .headerCon .navbox a.router-link-active{background:#003677; font-weight: bold;}

header .headerCon .langbox{font-size:16px;}
header .headerCon .langbox>span{margin:0 5px; cursor: pointer; user-select: none; opacity:.6;}
header .headerCon .langbox>span.che{opacity:1; color:#f8d799;}

header .subnav{height:36px; background:#e5e5e5; user-select: none; top:0; left:0; right:0;}
header .subnav .wrap{width:1100px; margin:0 auto; }
header .subnav .wrap ul{list-style: none; display: flex; margin:auto; justify-content: flex-start; margin-left:0; padding-left: 0; }
header .subnav .wrap ul li{line-height:36px; min-width: 125px; padding:0 12px; color:#333; font-size:14px; cursor:pointer; text-align: center;}
header .subnav .wrap ul li.che{background:#d6b79a; color:#000;}

header.en .headerCon .leftbox .logo{margin-right:62px;}
header.en .subnav .wrap{width:1200px;}
header.en .subnav .wrap ul{margin-left:0; padding-left: 0; justify-content: center;}
header.en .subnav .wrap ul li{font-size:12px;  padding:0 10px;}

.breadcrumb{color:#333; line-height: 36px;}
.breadcrumb>a{color:#333;}
.breadcrumb>a:hover{color:#042246; background:none; text-decoration: underline;}
.breadcrumb .arrow{margin:0 4px;}
header.en .breadcrumb{margin-left:50px;}
/* .breadcrumb>span::before,.breadcrumb>.span::before{content:'>'; color:#333;margin:0 4px; text-decoration: none;} */

@media (max-width: 600px) {
header .topbar{height:32px; line-height:32px;}
header .topbar .wrap{width:414px;font-size:14px;}
header .topbar .wrap img{margin:0 0px; width:32px; height:32px;}

header .headerCon{width:414px; padding:0 15px; margin-bottom:0px;}

header .headerCon .navbox{display: none;}
header .navbox-m{display:block; background:#fff; position: relative; height:48px; left:0; right:0; top:0px; box-shadow: 1px 1px 3px rgba(0,0,0,.2); z-index:999;}

header .navbox-m a{display:inline-block; padding:0 15px; height:52px; line-height:32px; color:#111; text-align:center; font-size:16px; transition-duration: 300ms;}
header.en .navbox-m a{padding:0 12px;}
header .navbox-m a>span{display: inline-block; margin:0 auto;  margin-top:6px; border-bottom:2px solid #fff;}
header .navbox-m a.active{background:none;}
header .navbox-m a.router-link-active>span{border-bottom:2px solid #e60b01; font-weight: bold;}
header .navbox-m a.back span{color:#333; font-weight: bold; line-height:36px;}
header .navbox-m a.back::before{content:''; display: inline-block; margin-right:2px; height:12px; width:12px; border-top:2px solid #666; border-left:2px solid #666; transform: rotate(-45deg);}

header .subnav{height:inherit;}
header .subnav .wrap{width:414px;  margin:0 0;}
header.en .subnav .wrap{width:414px;}
header .subnav .wrap ul{margin-left:0; display:block; padding:10px 15px;}
header .subnav .wrap ul li{display: inline-block; padding:0 5px; text-align: left; min-width: 136px; }
header .subnav .wrap ul li:nth-of-type(2n+1){margin-right:40px;}

.breadcrumb{display:none;}

}
</style>
